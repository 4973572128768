<p-confirmDialog
	#confirmDialog
	[closable]="false"
	data-tt-tour="ConfirmationDialog"
	styleClass="tt-dialog-medium-width">
	<ng-template data-tt-tour="ConfirmationDialog-Footer" pTemplate="footer">
		@if (confirmDialog.confirmation.rejectVisible === undefined || confirmDialog.confirmation.rejectVisible) {
			<button
				data-tt-tour="ConfirmationDialog-Button-Reject"
				type="button"
				[ngClass]="['me-2', confirmDialog.confirmation.rejectButtonStyleClass || 'tt-btn-cancel']"
				(click)="confirmDialog.reject()"
				autofocus>
				<span [class]="confirmDialog.confirmation.rejectIcon || 'tt-icon-cancel'" aria-hidden="true"></span>
				<span class="tt-btn-text">{{ confirmDialog.confirmation.rejectLabel }}</span>
			</button>
		}
		@if (confirmDialog.confirmation.acceptVisible === undefined || confirmDialog.confirmation.acceptVisible) {
			<button
				data-tt-tour="ConfirmationDialog-Button-Accept"
				type="button"
				[ngClass]="[confirmDialog.confirmation.acceptButtonStyleClass || 'tt-btn-delete']"
				(click)="confirmDialog.accept()">
				<span [class]="confirmDialog.confirmation.acceptIcon || 'tt-icon-delete'" aria-hidden="true"></span>
				<span class="tt-btn-text">{{ confirmDialog.confirmation.acceptLabel }}</span>
			</button>
		}
	</ng-template>
</p-confirmDialog>
