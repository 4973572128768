import { Injectable } from '@angular/core';
import { Mutation, MutationOptionsAlone, MutationResult } from 'apollo-angular';
import { Observable } from 'rxjs';

import { IUserRefreshSession_RequestVariables, IUserRefreshSession_ResponseData, UserRefreshSession_Mutation } from './user-refresh-session.mutation';

// Not in `UserMutationsService` to avoid circular dependency; Probably should split all `*MutationsService` methods into Apollo Angular Mutation services
@Injectable({ providedIn: 'root' })
export class UserRefreshSessionService extends Mutation<IUserRefreshSession_ResponseData, IUserRefreshSession_RequestVariables> {
	public document = UserRefreshSession_Mutation;

	public mutate(
		variables?: IUserRefreshSession_RequestVariables,
		options?: MutationOptionsAlone<IUserRefreshSession_ResponseData, IUserRefreshSession_RequestVariables>,
	): Observable<MutationResult<IUserRefreshSession_ResponseData>> {
		return super.mutate(variables, options);
	}
}
