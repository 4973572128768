import { Injectable } from '@angular/core';
import { Apollo, QueryRef, WatchQueryOptionsAlone } from 'apollo-angular';
import { isEqual } from 'lodash-unified';

import { ITrackedSubscription } from '../../services/gql/gql-client-subscription.service';
import { IAccountUpdated_RequestVariables, IAccountUpdated_ResponseData } from './account-updated.subscription';
import { AccountRead_Query, IAccountRead_RequestVariables, IAccountRead_ResponseData } from './account-read.query';
import { AccountUpdatedService } from './account-updated.service';
import { GQLClientQueryService } from '../../services/gql/gql-client-query.service';
import { MessageService } from '../../services/message-service/message.service';


/** Service class to invoke real-time updates to `watchQuery` via related Subscription & to centralize any update logic
 *  Prefer to using the query document directly with Apollo Client
 */
@Injectable({ providedIn: 'root' })
export class AccountReadService extends GQLClientQueryService<IAccountRead_ResponseData, IAccountRead_RequestVariables> {
	public document = AccountRead_Query;
	private subscriptionList: ITrackedSubscription<IAccountUpdated_ResponseData, IAccountUpdated_RequestVariables>[] = [];

	public constructor(
		apollo: Apollo,
		messageService: MessageService,
		private accountUpdatedService: AccountUpdatedService,
	) {
		super(apollo, messageService);
	}

	public watch(
		variables?: IAccountRead_RequestVariables,
		options?: WatchQueryOptionsAlone<IAccountRead_RequestVariables, IAccountRead_ResponseData>,
	): QueryRef<IAccountRead_ResponseData, IAccountRead_RequestVariables> {
		const watchQuery = super.watch(variables, options);
		const key = { variables: { accountId: variables.accountId } };
		let relatedSubscription = this.subscriptionList.find((trackedOperation: ITrackedSubscription) => {
			return isEqual(key, trackedOperation.key);
		});
		if (!relatedSubscription) {
			relatedSubscription = {
				key: key,
				subscription: this.accountUpdatedService.subscribe(key.variables),
			};
			this.subscriptionList.push(relatedSubscription);
			relatedSubscription.subscription.subscribe();
		}
		return watchQuery;
	}
}
