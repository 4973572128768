/** encapsulation class for `ContentAttachedEdit` action */
export class ContentAttachedEditMessage {
	public constructor(
		public contentId: string,
		public name: string,
		public description: string,
		public accountId: string,
	) {
	}
}
