import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, Query, QueryOptionsAlone, QueryRef, WatchQueryOptionsAlone } from 'apollo-angular';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MessageService } from '../message-service/message.service';
import { UserNotificationMessage, UserNotificationType } from '../message-service/user-notification-message.model';
import { getGQLErrorMessage } from '../../methods/get-gql-error-message.method';


/** Base class for GQL Queries: extend & assign `document` property in derived classes */
@Injectable({ providedIn: 'root' })
export abstract class GQLClientQueryService<ResponseType = unknown, RequestVariables = Record<string, unknown>> extends Query<ResponseType, RequestVariables> {
	public constructor(
		protected apollo: Apollo,
		protected messageService: MessageService,
	) {
		super(apollo);
	}

	public fetch(
		variables?: RequestVariables,
		options?: QueryOptionsAlone<RequestVariables, ResponseType>,
		notifyUserOnError = true,
	): Observable<ApolloQueryResult<ResponseType>> {
		return super.fetch(variables, options)
			.pipe(
				catchError((error: unknown, caught: Observable<ApolloQueryResult<ResponseType>>) => {
					if (notifyUserOnError) {
						this.messageService.publish(new UserNotificationMessage(getGQLErrorMessage(error), UserNotificationType.Error));
					}
					return throwError(() => error);
				}),
			);
	}

	public watch(
		variables?: RequestVariables,
		options?: WatchQueryOptionsAlone<RequestVariables, ResponseType>,
		notifyUserOnError = true,
	): QueryRef<ResponseType, RequestVariables> {
		const watchQuery = super.watch(variables, options);
		if (notifyUserOnError) {
			watchQuery.result().catch((error: unknown) => {
				this.messageService.publish(new UserNotificationMessage(getGQLErrorMessage(error), UserNotificationType.Error));
			});
		}
		return watchQuery;
	}
}
