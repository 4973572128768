import { Injectable } from '@angular/core';
import { QueryRef, WatchQueryOptionsAlone } from 'apollo-angular';

import { IUserRead_RequestVariables, IUserRead_ResponseData, UserRead_Query } from './user-read.query';
import { GQLClientQueryService } from '../../services/gql/gql-client-query.service';


/** Service class to provide default query options & shared update logic
 *  Prefer to using the query document directly with Apollo Client
 */
@Injectable({ providedIn: 'root' })
export class UserReadService extends GQLClientQueryService<IUserRead_ResponseData, IUserRead_RequestVariables> {
	public document = UserRead_Query;

	public watch(
		variables?: IUserRead_RequestVariables,
		options?: WatchQueryOptionsAlone<IUserRead_RequestVariables, IUserRead_ResponseData>,
	): QueryRef<IUserRead_ResponseData, IUserRead_RequestVariables> {
		return super.watch(variables, options);
	}
}
