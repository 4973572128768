import { OrganizationRole } from '../organization-role.type';

export class OrganizationUpdateSubscriptionPermission {
	public isAuthorized: boolean = false;
	public message: string;

	public constructor(
		organizationRole: OrganizationRole,
		newBillingPlanIsFree: boolean,
		paymentMethodIsValid: boolean,
		ownedOrganizationOnFreePlanCount: number,
	) {
		switch (true) {
			case organizationRole !== OrganizationRole.Owner:
				this.message = 'Organization Owner Role required to update Plan';
				break;
			case newBillingPlanIsFree && ownedOrganizationOnFreePlanCount > 0:
				this.message = 'Only 1 free Organization allowed per User';
				break;
			case !paymentMethodIsValid && !newBillingPlanIsFree:
				this.message = 'Payment Source required to change to paid Plan';
				break;
			default:
				this.message = 'Update Plan';
				this.isAuthorized = true;
				break;
		}
	}
}
