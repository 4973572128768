import { isRefType } from '@typegoose/typegoose';

import { DomainEntityBase } from '../../core/domain/domain-entity-base.model';
import { Content, IContent } from '../content/content.model';
import { DomainEntityTypeName } from '../../core/domain/domain-entity-type-name.types';
import { newEntityId } from '../../core/domain/entity-id.methods';
import { IProcedure } from '../procedure/procedure.model';
import { OmitMethodKeys } from '../../core/infrastructure/types/utility.types';
import { populate } from '../../core/infrastructure/methods/populate.method';
import { IEntityActivity } from '../../common/activity-list/entity-activity.model';
import { IAccount } from '../../common/account/account.model';
import { IProcedureStepDocument } from './procedure-step-document.type';


/** Data Transfer Object of `ProcedureStep`
 *  Methods removed & properties marked optional to create accurate GraphQL DTO of Domain Entity
 *
 *  @ToDo: consider renaming with `DTO` suffix
 */
export interface IProcedureStep extends Partial<OmitMethodKeys<ProcedureStep>> {
	siblingPrevious?: IProcedureStep; // Managed by `@client` resolver
	siblingNext?: IProcedureStep; // Managed by `@client` resolver
}

/** domain model class: An individual checklist item constituting the building block of a Procedure */
export class ProcedureStep extends DomainEntityBase {
	public name: string = '';
	public detail: string;
	public contentList: IContent[] = []; // Managed by external resolver
	public procedureStepList: IProcedureStep[] = [];
	public defaultAssigneeAccount: IAccount; // Managed by external resolver
	public parentProcedureStep: ProcedureStep;
	public procedure: IProcedure;
	public activityList: IEntityActivity[] = []; // Managed by external resolver
	public readonly __typename: DomainEntityTypeName.ProcedureStep = DomainEntityTypeName.ProcedureStep;

	public constructor(procedure: IProcedure, initialValues?: IProcedureStepDocument, parentProcedureStep?: ProcedureStep) {
		super();
		this.procedure = procedure;
		if (initialValues) {
			populate<ProcedureStep>(this, initialValues);
			this.parentProcedureStep = parentProcedureStep;
			this.defaultAssigneeAccount = isRefType(initialValues.defaultAssigneeAccountId, String) ? { _id: initialValues.defaultAssigneeAccountId } : undefined;
			this.contentList = initialValues.contentIdList.map((contentId) => {
				return isRefType(contentId, String) ? { _id: contentId } : new Content(contentId);
			});
			this.procedureStepList = initialValues.procedureStepDocumentList.map((childDocument: IProcedureStepDocument) => {
				return new ProcedureStep(procedure, childDocument, this);
			});
		}
		this._id = this._id || newEntityId();
	}
}
