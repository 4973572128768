/** encapsulation class for `ContentLinkedEdit` action */
export class ContentLinkedEditMessage {
	public constructor(
		public contentId: string,
		public name: string,
		public payload: string,
		public description: string,
		public accountId: string,
	) {
	}
}
